body{
    min-height: 100vh !important;
    height: auto !important;
}

.header{
    z-index: 100;
}

.header-row{
    z-index: 1000;
    background-color: white;
    display: flex;
    width: 100%;
}
.header-row-navigation{
    z-index: 1001;
    position: sticky;
    top:0;
    border-bottom: 2px solid var(--bs-primary);
}
.navbar-logo{
    height: 110px;
    padding: 5px 0;
}

.navbar{
    display: flex;
    align-items: center;
    padding:0;
}

.nav-list{
    display: flex;
    align-items: center;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}
.nav-list-children{
    visibility: hidden;
    background-color: white;
    display: flex;
    align-items: start;
    flex-direction: column;
    top: calc(100% + 2px);
    left: 0;
    position: absolute;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.nav-list-children .nav-list-children {
    border-left:1px solid var(--bs-primary);
    left: 100%;
    top: 0;
}

.nav-item:hover{
    background-color: var(--bs-light);
    border-bottom: 2px solid var(--bs-primary);
}

.nav-item:hover  >  .nav-item-link + .nav-list-children{
    visibility: visible;
}


.nav-item-link:has(+ .nav-list .nav-item)::after{
    content: "\25BE";
    padding-left: 8px;
    margin-left: 8px;
    border-left: 1px solid var(--bs-light);
}
  
.nav-list-children .nav-item-link:has(+ .nav-list .nav-item){
    content: "\25B8";
}

.nav-item{
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    position: relative;
    padding: 10px;
}

.nav-item-link{
    font-weight: 600;
    display: flex;
    align-items: center;
    width: 100%;
}


.nav-item-icon {
    color: var(--bs-primary);
    display: flex;
    margin-right: 1rem;
}

.nav-list-children .nav-item {
    flex-direction: row;
    width:100%;
    border-bottom: 1px solid var(--bs-primary);
}


ul:not(.u-unstyled) {
    margin-top: auto;
    margin-bottom: auto;
    padding-left: auto;
    font-weight: auto;
}

.tp-widget-rating{
    margin: 0 !important;
    font-size: 12px !important;
}


@media only screen and (max-width: 900px) {
    .header-row-navigation{
        padding: 0 10px;
    }
    .navbar-products {
      display: none;
    }
    .navbar-pages {
        display: none;
    }
    .navbar-productgroup{
        display: none;
    }
    .menu-collapse{
        display: flex !important;
    }

    .navbar-logo{
        height: 70px;
    }
    
}


.backdrop{
    height:100vh;
    width:100vw;
    background-color: rgba(0, 0, 0, 0.4);
}

.mobile-navbar{
    margin-left: auto;
    height:100vh;
    min-width: 70%;
    padding: 10px; 
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1;

}
.mobile-sidebar-close{
    display: flex;
    font-size: 25px;
}
.menu-collapse{
    width: 24px;
    height: 24px;
}
.mobile-menu{
    display:none;
    position: fixed;
    width:100%;
    height:100vh;
    top: 0;
    right: 0;
    // padding: 20px 10px;
    background-color: #ffffffe8;
    z-index: 1001;
}
.mobile-menu.show{
    display:flex;
    flex-direction: row;
}

.mobile-nav-list{
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

}
.mobile-nav-list-children {
    display: none;
    background-color: #f8f9fa45;
    border-right: 1px solid var(--bs-primary);
    border-left: 1px solid var(--bs-primary);
}

.mobile-nav-item{
    display: flex;
    align-items: center;
    flex-direction: column;
    white-space: nowrap;
    position: relative;
    padding: 10px;
    border-bottom: 2px solid var(--bs-primary);
}
.mobile-nav-list-children .mobile-nav-item{
    border-bottom: 0 solid var(--bs-primary);
}

.mobile-nav-link:has(+ .mobile-nav-list .mobile-nav-item){
    padding-left:24px;
}
.mobile-nav-link:has(+ .mobile-nav-list .mobile-nav-item)::after{
    content: "\25BE";
    padding-left: 8px;
    margin-left: 8px;
    border-left: 1px solid var(--bs-light);
}

.mobile-nav-link.show-children + .mobile-nav-list {
    display:flex;
}


.navbar-home .nav-list{
    display: none;
}
.navbar-products{
    margin-left: auto;
}



// .header-row:not(.sticky-header){
//     background-color:transparent !important;
//      color:white !important;
//      position: absolute;
//  }
// .header-row:not(.header-row-navigation){
//      display: none !important;
//  }

//  .header-row:not(.sticky-header) .nav-item-link a{
//      color:white;
// }
