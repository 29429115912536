#side-form-block{
    position: sticky;
    z-index: 1002;
  }

  .collapse-button{
      color:white;
      vertical-align: baseline;
      position: fixed;
      top: 50%;
      right: 0;
      z-index: 100;

  }
  .side-button h6{
      color:white;
      font-size: 12px;
      -webkit-transform: rotate(270deg); /* Safari & Chrome */
      -moz-transform: rotate(270deg); /* Firefox */
      -ms-transform: rotate(270deg); /* Internet Explorer */
      -o-transform: rotate(270deg); /* Opera */
      transform-origin: right bottom;
      background-color: var(--bs-primary);
      border: 1px solid var(--bs-border-color);
      border-bottom: 0;
      opacity: 0.75;
      width: fit-content;
      padding: 0.4rem 1rem;
  }
  #side-form-block.show .side-button {
      display:none;

  }
  #side-form-block.show .side-button {
      transform: translateY(-0%) rotate(-0);
      -webkit-transform: rotate(0); /* Safari & Chrome */
      -moz-transform: rotate(0); /* Firefox */
      -ms-transform: rotate(0); /* Internet Explorer */
      -o-transform: rotate(0); /* Opera */

      transform-origin: right bottom;
      
      border: 1px solid grey;
      opacity: 1;
      top: 0;
  }
  #side-form-block.show .collapse-button{
      top: 0;
  }
  .side-form{
      position: absolute;
      right: -430px;
      -webkit-animation: visibility slide 0.5s forwards;
      -webkit-animation-delay: 2s;
      animation: slide 0.5s forwards;
      transition:2s;
      border-left: 1px solid #d1d1d1;
      height: 100vh;
      background-color: white;
      visibility: hidden;
      overflow: auto;
      width: 0;

  }
  #side-form-block.show .side-form{
      visibility: visible;
      opacity: 1;
      width: 430px;
  }

  #side-form-block.show .wrapper{
    display:block;
  }
  .wrapper{
    position: relative;
    display:none;
    overflow: hidden;
    width: 430px;
    height: 100vh;

  }
  .show .form-wrapper{

  }
  #replacing-account-form{
    
  }
  @media only screen and (max-width: 600px) {
    #side-form-block.show .side-form{
      width: 100vw;
      border-left: 0px solid darkgrey;

    }
    #side-form-block.show .wrapper{
      width: 100vw;
    }
  }

  @-webkit-keyframes slide {
      100% { right: 0; }
  }

  @keyframes slide {
      100% { right: 0; }
  }
  #close-form{
    left:0;
  }
  #close-form:hover{
    cursor: pointer;
  }