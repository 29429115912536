
@import "./bootstrap_variables.scss";

.col-form-label{
    font-size: 14px !important;
    font-weight: 500;
    padding-top: 0!important;
    padding-bottom:0!important;
}
.radio-card label{
    min-width:100px;

}
.radio-card label:hover{
    border:0;
}

.select2-container{
    width: 100% !important;
}
.select2-results__options{
    padding:0px !important;
    margin:0px !important;
}
.select2-results__option{
    line-height: 1rem;
    font-size: 15px;
    font-weight: 400;
}
.select2-container--default .select2-selection--single {
    border: 1px solid #ced4da !important;
    border-radius: $border-radius !important;
    height: 34px !important;

}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    height: 34px !important;
    line-height: 34px !important;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
    margin-top: 1px !important;
}

.radio-card {
    display: flex;
    padding-left: 0;
    padding-right: 0;
    margin: 0 8px 4px 0;
    border-left: 5px solid #4b0f1000;
    border-radius: 4px;
}

.radio-card:hover, .radio-card:has(input:checked ){
    border: 1px solid #b7b7b7;
    border-left: 5px solid #4b0f10;

}



@import "../../../node_modules/bootstrap/scss/bootstrap";
