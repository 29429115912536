a{
    text-decoration: none !important;
}

.alert{
    font-size: 14px;
    padding: .5rem 1rem;
    display: flex;
    align-items: center;
}

// // .btn-transform{
// //     background: linear-gradient(to left, white 50%, var(--bs-primary) 50%) right;
// //     background-size: 200%;
// //     transition: .5s ease-out;
// //     padding: 8px 12px;
// // }
// // .btn-transform:hover{
// //     background-color: var(--bs-primary) !important;
// //     background-position: left;
// //     color:white  !important;
// // }
// .btn-close{
//     position: relative !important;
//     margin-left:auto;
//     padding:.5rem !important;
// }


// label:has(input:checked ) {
//     color: var(--bs-btn-active-color);
//     background-color: var(--bs-light);
//     border: 0;
// }

// label:has(input[disabled] ) {
//     background-color: #ffdada !important;
// }

// .small-page{
//     max-width: 800px;
//     margin-bottom: 5rem !important;
//     min-height: 600px;
// }

// .btn-primary{
//     border-radius: 1px;
//     background-color: white;
//     color: var(--bs-primary);
//     border: 2px solid;
//     padding: 6px 15px;
//     margin: 4px 0;
//     line-height: 1.1;
//     font-size: 0.875rem;

// // }
// .u-carousel-thumbnail-1:hover {
//     transform: scale(1.05);
// }
// .option-row{
//     margin: 0 4px !important;
// }
// .option-row > *{
//     padding: 0px;
// }
// .radio-wrapper{
//     display: flex;
//     align-items: center;
//     overflow: auto;
//     padding: 10px 4px;
//     border-top: 1px solid #e6e6e6;
//     border-bottom: 1px solid #e6e6e6;
// }


// label.btn{
//     padding: 12px 20px;
//     border: 1px solid #b7b7b7;
//     background-color: white;
//     box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0, 0, 0.19);
// }
// .label-group input[type="radio"] {
//     display: none;
// }
// .btn-primary:hover{
//     background-color: var(--bs-primary);
//     color: white;
//     cursor:pointer
// }

/* hide/show like/dislike button */
// .shower .show,.shower:hover .no_show {
//     display: block !important;
    
// }
// .shower:hover .show,.shower .no_show {
// display: none !important;

// }

/* hover whatsapp icon product_detail*/
.u-layout-cell .u-social-whatsapp:hover{
color: #25D366!important;
}

.u-container-style:hover .u-icon:hover{
color: #4D4D4D !important;
}

// .checkout-options{
//     padding: 0.35em 0.625em 1.25em !important;
//     border: 1px solid lightgrey !important;
//     }
    
    
// .datepicker{
//     width: 120px;
//     border-radius: 0.2rem;
//     border: 1px solid lightgrey;
//     padding: 0px 3px;
    
// }
    
// .selectpicker{
//     padding: 4px 3px;
//     border-radius: 0.2rem;
//     border: 1px solid lightgrey;

// }

// .homedelivery_hide.true{
//     display:none !important;
// }



/* Logo image Header */
.u-header .u-image-1 {
    width: 110px;
    height: 70px;
    margin: 0 auto 0 38px;
}

.sticky-hide{
    display: none !important;
}
.sticky-show{
    display: table !important;
}
    


// /* width */
//   div ::-webkit-scrollbar {
//     width: 5px;
//     height: 5px;
//   }
  
//   /* Track */
//   div ::-webkit-scrollbar-track {
//     box-shadow: inset 0 0 5px grey; 
//     border-radius: 10px;
//   }
   
//   /* Handle */
//   div ::-webkit-scrollbar-thumb {
//     background: var(--bs-primary); 
//     border-radius: 10px;
//   }
  
//   /* Handle on hover */
//   div ::-webkit-scrollbar-thumb:hover {
//     background: #b30000; 
//   }
  

.contact-socials {
    list-style-type: none;

}

footer{
    background-color: var(--bs-primary);
    color: #ffffff;
    padding-top:50px
}

footer .footer-navigation{
    padding:0px 10px;
    list-style-type: none;
}
footer ul{
    padding:0px !important;
    list-style-type: none;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 30px;
}
footer .nav-link{
    color:#ffffff;
    padding: 0px;
}

footer .nav-link:hover{
    color:lightgray;
}
.contact-nav{
    display: flex;
    justify-content: center;
    max-width: 40%;
    margin: 0 auto;
}
footer .contact-nav .flex-grow{
    flex-grow:1;
}
.footer-logo{
    width:100%;
    height:100%;
}

.footer-logo-image{
    max-width:100%;
    max-height:100%;
}

.footer-icn{
    font-size: 20px;
    padding: 0px 8px !important;
}

.footer-col {
    text-align: center;
}
.footer-icon-col{
    align-items: center;
}

.footer img{
    object-fit: contain; 
}

.footer-logo-image{
    max-height: 120px;
}

.footer-logo {
    justify-content: center;
}

.payment-icon {
    font-size:20px;
    padding:0 8px;
}

.footer-icn:hover, .payment-icon:hover{
    transform: scale(1.2);
}

.footer-divider{
    border-bottom: 1px solid white;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}
@media (min-width: 576px) {
    
    .footer-icon-col{
        align-items: center;
    }

}

@media (min-width: 768px){
    footer .nav-link{
        max-width: 100%;
    }

    .footer-col {
        text-align: start;
    }
    .footer-icon-col{
        align-items: start;

    }
    .footer-logo {
        justify-content: start;
    }
    
}

@media (min-width: 992px){
    .col-right{
        align-items: end;
    }
    
}